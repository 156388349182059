import Layout from "../components/layout";
import { Link } from "gatsby";

import React, { Component } from "react";
import e1 from "../images/posters/1.png";
import e2 from "../images/posters/2.png";
import e3 from "../images/posters/3.png";
import e4 from "../images/posters/4.png";
import e5 from "../images/posters/5.png";
import e6 from "../images/posters/6.png";
import e7 from "../images/posters/7.png";
import e8 from "../images/posters/8.png";
import e9 from "../images/posters/9.png";
import e10 from "../images/posters/10.png";
import e11 from "../images/posters/11.png";
import e12 from "../images/posters/12.png";
import e13 from "../images/posters/13.png";
import e14 from "../images/posters/14.png";
import e15 from "../images/posters/15.png";
import e16 from "../images/posters/16.png";
import e17 from "../images/posters/17.jpeg";
import e18 from "../images/posters/18.jpeg";

import Carousel, { ModalGateway, Modal } from "react-images";

class PostersGallery extends Component {

  constructor (props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  componentDidMount () {

  }

  openLightBox (index) {
    this.setState({
      isOpen: true,
      photoIndex: index
    });

  }

  render () {
    const data = [
      {
        image: e1,
        text: "A  Master class was delivered by Ms. Inu Rana on the topic “Building a Global Startup from a Technology Incubator” to the CAP COHORT-II students on 1st September 2020 from 11:00 AM- 12:00 PM via ZOOM platform. Ms. Inu Rana mentioned that one should start a startup at a right time and should give at least ten years so that it can turn into a great success. She stated that a successful entrepreneur should have the ability of taking right decisions at right time."
      },
      {
        image: e2,
        text: "A virtual panel discussion on “Encouraging Entrepreneurship in Classrooms” was organized by CEED on 11th September 2020 for the faculty of Chitkara University, in order to encourage faculty members to start their startups. The key Panelists for the event were Dr. Varinder Kanwar, VC, Chitkara University, HP, Dr. Manoj Manuja, Pro VC, Chitkara University, Punjab and Mr. S.R. Dhanasekaran, Vice President, Office of Skills and strategic Initiatives, Chitkara University, Punjab."
      },
      {
        image: e3,
        text: "A  Master class was delivered by Ms. Inu Rana and Mr. Don Wright on the topic “Global Market Dynamics” to the CAP COHORT-II students on 15th September 2020 from 11:00 AM- 12:00 PM via ZOOM platform. According to the experts, today’s globalization of consumer markets has a profound effect on the global economy and consumer spending patterns have changed dramatically. Economists therefore recognize the need for international collaboration in analyzing socio-economic activity in order to capture consumer behaviour on a global scale."
      },
      {
        image: e4,
        text: "A panel discussion on the topic “Managing Finance: The Mantra to a Startup Success” was organized by CEED, Chitkara University on 22th September 2020. Mr. Vineet Khurana, Vice President, Chandigarh Angels Network moderated the session. The key panelists for the event were Mr. Rajat Bhatia, CA, Chitkara University, Mr. Sahil Gupta, CA, Duggal Gupta & associates and Dr. Kiran Mehta, Professor of Finance, Chitkara University, Punjab"
      },
      {
        image: e5,
        text: "A virtual session on the topic “Incubating Big Ideas: A Peeping into the Journey of an Academy Incubator” delivered by Dr Puran Singh, Head-IIT Mandi Catalyst was organized by CEED, Chitkara University on 25th September 2020. Mr. Sumeer Walia, Director (CEED) moderated the session. Dr. Puran Singh talked about the entrepreneurship ecosystem of IIT Mandi and how they manage the whole startups and grants."
      },
      {
        image: e6,
        text: "A virtual session on “Will Startups Survive? This Human & Economic Catastrophe” was organized by CEED on 20th June 2020, in order to discuss and address the financial and economic implications of this pandemic which is causing significant dislocations & disruptions for startups, worse than those of any crisis in the past. The key Panelists for the event were Mr. Samar Singla, Founder CEO/Founder at Jugnoo and Mr. Pawan Gupta, Co-Founder and CEO of Curofy."
      },
      {
        image: e7,
        text: "The Youth Icon's and Innovation Series is a stage to feature and speak with business people who are committed to make a change in the society. The event held on 12th June 2020 with an aim to focus on how youthful Indians think, adjust and convey. The chief speaker for the event was Ms. Smita Mishra, Founder Fandoro and the session was moderated by Mr. Ankit Bhateja, Founder, Xovian Aerospace, TEDx speaker."
      },
      {
        image: e8,
        text: "A Master Class under the “Youth Icon's & Innovator series” on the topic “Engineering a Startup on Campus\" was organized by team CEED on 4th August 2020 from 11:00 A.M -12 00 PM. YourMaali is a team of 3 student founders who are heading horticulture related Startup “Your Maali” backed by research, is a unique symbol of Chitkara University’s efforts on developing entrepreneurship at Campus Level. YourMaali is currently working on vertical gardens, kitchen gardens & terrace gardens for offices, institutions & colleges."
      },
      {
        image: e9,
        text: "A session under the “Youth Icon's & Innovator series” on the topic “Engineering safe and resilient cities \" was organized by team CEED on 8th August 2020 from 10:30 A.M -11.30 A.M. The session was delivered by Mr. Arbab Ahmad ,an award winning, genius and a gifted architect who has brought unique innovations in urban planning and been rewarded by State and National government. Mr Arbab Ahmad talked about his journey and shared his thoughts about the opportunities that are available for architecture students in entrepreneurship."
      },
      {
        image: e10,
        text: "A master class on the topic \"Idea Vetting & Idea Validation\" was delivered by Mr. Sumeer Walia, Director , CEED to the students of CAP COHORT-II on 11th August 2020. Around 55 students from CAP COHORT-II attended the session. This class was organized in order to give the students a detailed overview about the ideas validation."
      },
      {
        image: e11,
        text: "On the occasion of 74th Independence Day, 15th August 2020, a virtual session on “Kuch kar Dikhana Hai Entrepreneur Ban Dikhana Hai\" was organized by CEED where Chitkara University’s startup brigade shared their thoughts and passion towards building a great Nation. According to the young startups , that it is the responsibility of youngsters to take the “Make in India” movement forward. Also, there should be compulsory teaching of Entrepreneurship at school level."
      },
      {
        image: e12,
        text: "A master class was delivered by Mr. Abhiraj, Founder School pad to the students of CAP COHORT-II on 18th August 2020. Around 48 students from CAP COHORT-II attended the session. Mr. Abhiraj focused on some points which every budding entrepreneur should follow and these points include: Intention, Focus and Perseverance. He mentioned that one should have the right intention of doing the business. If one’s intention is not right, then startup will not be successful. Also the focus should be right"
      },
      {
        image: e13,
        text: "A virtual Panel discussion on  \"Steer your Startup to Success”  was organized by CEED, Chitkara University on 25th August 2020 . The key panelists for the session were Dr. Apoorva Ranjan Sharma, Co-founder Venture Catalysts & 9 Unicorns, Mr. Ashutosh Burnwal, Co-founder, Buddy4Study.com, Mr. Harshad Lahoti, Seed/Angel/Venture Capital & Private Equity – Mentor. According to the Panelists, five things which are very important for any person who wants to be a entrepreneur is: Clarity of thoughts, Integrity, Quick Decision Making, Good negotiation skill & Networking ability."
      },
      {
        image: e14,
        text: "A virtual session on “Sales for Startups” was organized by CEED, Chitkara University on 28th August 2020. The Key resource person for the session was Mr. Subramanian Chandramouli. Mr. Subramanian Chandramouli discussed four simple things to increase the sales which are as following:\n" +
          "1. Five follow ups in a day\n" +
          "2 Five leads every 3 days\n" +
          "3. Three virtual meetings every 3 days\n" +
          "4. Five proposals every 15 days"
      },
      {
        image: e15,
        text: "A full marathon of 42.19 km is considered to be Test of mental and physical Strength. The third edition of “The youth Icon’s and Innovator Series” witnessed Ms. Apoorva chaudhary \"The ultra runner\" in conversation with Mr. Sumeer Walia Director-CEED. Ms. Apoorva gave insights of her journey how being in IT sector she gets along with your athletic passion. Coming from a background where women is not supported, how she became the only 1st women to run 200 kilometres in 24 hours."
      },
      {
        image: e16,
        text: "Opening Session for CAP Cohorot-II was conducted by CEED.  Mr Vibhore, Tech Alchemist & former Chief Technology Officer- Naukari.com was the chief speaker and the session was moderated by Mr. Sumeer Walia. Mr. Vibhore talked about the latest technology trends, he added how working on open source coding projects can provide a great exposure for tech students in Universities."
      },
      {
        image: e17,
        text: "A virtual session on the topic “Raising Agri based startup from an incubator\" was organized by team CEED on 9th October 2020 from 2:30 P.M 3.30 P.M. The session was delivered by Mr. Ram Dhulipala, Theme leader –Digital Agriculture & Youth Innovation Systems Drylands, ICRISAT, Hyderabad. Mr Ram Dhulipala talked about his journey and shared his thoughts about the opportunities that are available for agriculture students in entrepreneurship. He added that agri based startups are well supported by the government."
      },
      {
        image: e18,
        text: "A panel discussion on the topic “Bringing Business Back on Track\" was organized by team CEED on 13th October 2020 from 11:00 A.M 12:00 P.M. According to the panelists the COVID-19 pandemic has suddenly upset each part of business activities. Millions of people lost their jobs and a staggering number of businesses are forced to shut down but there is still a silver lining that one need to focus on if anyone want to turn the odds in his favor. "
      }
    ];
    const { photoIndex, isOpen } = this.state;
    return (
      <Layout>
        <section id="section-page2" className="section general-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8  text-center">
                <div className="page-heading">
                  <h2 className="display-4">Posters Gallery</h2>

                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item"><Link to={"/"}>Home </Link></li>
                    <li className="list-inline-item"><a>/</a></li>
                    <li className="list-inline-item"><a href="#" className="text-muted">Gallery</a></li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section gallery">
          <div className="container">
            <div className="images">
              <div className="row gutter">
                {
                  data.map((item, index) => {
                    return (
                      <div key={index} className="col-lg-6 column">
                        <a className="image"
                           style={{ backgroundImage: "url(" + data[index].image + ")" }}
                           onClick={this.openLightBox.bind(this, index)}/>
                        <p>{data[index].text}</p>
                      </div>
                    );
                  })
                }
              </div>
            </div>
            <ModalGateway>
              {isOpen ? (
                <Modal onClose={() => this.setState({ isOpen: false })}>
                  <Carousel currentIndex={photoIndex} views={data.map(({image}) => {
                    return {
                     source : image
                    };
                  })}/>
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </section>
      </Layout>
    );
  }
}

export default PostersGallery;

